import { LayerIcon } from "../../../assets/icons";
import { ReactComponent as AnalysisIcon } from "../../../assets/icons/analysis.svg";
import { ReactComponent as OperationIcon } from '../../../assets/icons/navigation/operations.svg';
import { ReactComponent as DoubleArrowIcon } from "../../../assets/icons/operation/double-arrow.svg";
import { ReactComponent as MarkerIcon } from "../../../assets/icons/operation/marker.svg";
import { ReactComponent as PhaseIcon } from "../../../assets/icons/operation/phase.svg";
import { ReactComponent as PositionIcon } from "../../../assets/icons/operation/position.svg";
import { ReactComponent as SampleIcon } from "../../../assets/icons/operation/sample.svg";
import { ReactComponent as ThicknessIcon } from "../../../assets/icons/operation/thickness.svg";
import { ReactComponent as CarotteIcon } from '../../../assets/icons/synoptique-bottom-panel/carotte.svg';
import { ReactComponent as PrelevementIcon } from '../../../assets/icons/synoptique-bottom-panel/prelevement.svg';
import { ReactComponent as SondageIcon } from '../../../assets/icons/synoptique-bottom-panel/sondage.svg';
import { Lot } from '../../../models/lot';
import { Marker } from '../../../models/marker';
import { Operation, PhaseLabel } from '../../../models/operation';
import { Population, PopulationTypeLabel } from '../../../models/population';
import { Sample, SampleType } from '../../../models/sample';
import { formatDate, formatNumberToFixedDecimal } from '../../../utils/format';
import { isType } from "../../../utils/objects";
import { floatToPrString } from '../../../utils/pr';
import './index.scss';

export const sampleTypeIcon = (sample: Sample) => {
    switch (sample.type) {
        case SampleType.CAROTTE:
            return <CarotteIcon className="sample-icon" />;
        case SampleType.SONDAGE:
            return <SondageIcon className="sample-icon" />;
        default:
            return <PrelevementIcon className="sample-icon" />;
    }
}

interface IdentityCardProps<T> {
    entity: T;
    onClick?: (e: T) => void
}

const IdentityCard = ({ entity }: { entity: any }) => {
    if (isType<Lot>(entity, (e) => e.hasOwnProperty('position'))) {
        return <IdentityCardLot entity={entity} />
    } else if (isType<Sample>(entity, (e) => e.hasOwnProperty('layers'))) {
        return <IdentityCardSample entity={entity} />
    } else if (isType<Marker>(entity, (e) => e.hasOwnProperty('pathologies'))) {
        return <IdentityCardMarker entity={entity} />
    } else if (isType<Population>(entity, (e) => e.hasOwnProperty('problematicDataCount'))) {
        return <IdentityCardPopulation entity={entity} />
    } else if (isType<Operation>(entity, (e) => e.hasOwnProperty('symoptique'))) {
        return <IdentityCardOperation entity={entity} />
    } else {
        return (null);
    }
}

export const IdentityCardLot = ({
    entity,
    onClick
}: IdentityCardProps<Lot>) => (
    <div className="identity-card" onClick={() => typeof onClick === 'function' ? onClick(entity) : undefined}>
        <div className="identity-card-header">
            <span className="identity-card-name"><LayerIcon />{entity.fullLot} - {entity.materialPopulated?.name ?? ''}</span>
            <span className="identity-card-date">{formatDate(entity.date)}</span>
        </div>
        <div className="identity-card-content">
            {!!entity.zone?.prStart && !!entity.zone?.prEnd && <div className="identity-card-detail identity-card-detail-full"><DoubleArrowIcon />{floatToPrString(entity.zone.prStart)} à {floatToPrString(entity.zone.prEnd)}, sens {entity.way}</div>}
            <div className="identity-card-detail identity-card-detail-full"><ThicknessIcon />{entity.thickness.toFixed(2) + 'cm'}</div>
        </div>
    </div>
)

export const IdentityCardSample = ({
    entity,
    onClick
}: IdentityCardProps<Sample>) => (
    <div className={`identity-card ${entity?.controle ?? ''}`}>
        <div className="identity-card-header">
            <span className="identity-card-name">{sampleTypeIcon(entity)}{entity.name}</span>
            <span className="identity-card-date">{formatDate(entity.date)}</span>
        </div>
        <div className="identity-card-content">
            {entity.location?.pr && <div className="identity-card-detail"><PositionIcon />{floatToPrString(entity.location?.pr)}</div>}
            <div className="identity-card-detail"><ThicknessIcon />{entity.thickness.toFixed(2) + 'cm'}</div>
            <div className="identity-card-detail"><PhaseIcon />{PhaseLabel[entity.phase] ?? ''}</div>
            {entity?.fullLot && <div className="identity-card-detail"><LayerIcon />Lot {entity.fullLot}</div>}
        </div>
    </div>
)

export const IdentityCardMarker = ({
    entity,
    onClick
}: IdentityCardProps<Marker>) => (
    <div className="identity-card">
        <div className="identity-card-header">
            <span className="identity-card-name"><MarkerIcon className="sample-icon" />{entity.name}</span>
            <span className="identity-card-date">{formatDate(entity.date)}</span>
        </div>
        <div className="identity-card-content">
            {entity.location?.pr && <div className="identity-card-detail"><PositionIcon />{floatToPrString(entity.location?.pr)}</div>}
            <div className="identity-card-detail"><PhaseIcon />{PhaseLabel[entity.phase] ?? ''}</div>
        </div>
    </div>
)

export const IdentityCardPopulation = ({
    entity,
    onClick
}: IdentityCardProps<Population>) => (
    <div className={`identity-card ${entity?.controle ?? ''}`}>
        <div className="identity-card-header">
            <SampleIcon className="sample-icon" />
            <span className="identity-card-name">{PopulationTypeLabel[entity.type] ?? ''}</span>
        </div>
        <div className="identity-card-content">
            <div className="identity-card-detail"><AnalysisIcon />{formatNumberToFixedDecimal(entity.problematicDataCount)}</div>
            {entity?.fullLot && <div className="identity-card-detail"><LayerIcon />Lot {entity.fullLot}</div>}
        </div>
    </div>
)



export const IdentityCardOperation = ({
    entity,
    onClick
}: IdentityCardProps<Operation>) => (
    <div className="identity-card-container">
        <div className="identity-card-icon">
            <OperationIcon className="sample-icon stroke" />
        </div>
        <div className="identity-card">
            <div className="identity-card-header">
                <span className="identity-card-name">{entity.name}</span>
            </div>
            <div className="identity-card-content">

            </div>
        </div>
    </div>
)

export default IdentityCard;