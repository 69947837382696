import { useCallback, useState } from 'react';
import { Coordinates } from '../../../models/location';
import { MapAction } from '../../data/Map/Map';
import MapModal from '../../data/Map/MapModal';
import TextInput from '../TextInput';
import IconLink from '../../ui/IconLink';
import './index.scss';
import { FormErrors } from '../../../hooks/useForm';
import NumberInput from '../NumberInput';

interface PositionPickerProps {
    type: 'location' | 'zone';
    initialPosition?: Coordinates;
    location?: string;
    marker?: Coordinates;
    polygon?: Coordinates[];
    backgroundPolygon?: Coordinates[];
    onChange: (field: string, value: any) => void;
    errors?: FormErrors;
    action?: MapAction;
}

const PositionPicker = ({
    type,
    initialPosition,
    location,
    marker,
    polygon,
    backgroundPolygon,
    onChange,
    errors,
    action = MapAction.MARKER,
}: PositionPickerProps) => {
    const [isMapModalVisible, setMapModalVisible] = useState(false);

    const handleSubmit = useCallback((data: { marker?: Coordinates, polygon?: Coordinates[] }) => {
        if (type === 'location' && data.marker) {
            onChange('location.coordinates', { ...data.marker, manual: true });
        }
        if (type === 'zone' && data.polygon) {
            onChange('zone.polygon', data.polygon);
        }
        if (type === 'zone' && data.marker) {
            onChange('zone.marker', data.marker);
        }
        setMapModalVisible(false);
    }, [type, onChange]);

    return (
        <div className="input-column position-picker">
            <label>
                Localisation
                <IconLink type="position" label="Sélectionner sur la carte" onClick={() => setMapModalVisible(true)} />
            </label>
            <div className="position-picker-inputs">
                <TextInput
                    id="location"
                    placeholder="Point de repère géographique"
                    value={location}
                    onChange={(v) => onChange(type + '.location', v)}
                    errors={errors?.[type + '.location']}
                />
                <NumberInput
                    id="longitude"
                    value={marker?.longitude}
                    placeholder="Longitude"
                    onChange={(v) => onChange(type === 'location' ? 'location.coordinates.longitude' : 'zone.marker.longitude', Number(v))}
                    errors={errors?.[type === 'location' ? 'location.coordinates.longitude' : 'zone.marker.longitude']}
                />
                <NumberInput
                    id="latitude"
                    value={marker?.latitude}
                    placeholder="Latitude"
                    onChange={(v) => onChange(type === 'location' ? 'location.coordinates.latitude' : 'zone.marker.latitude', Number(v))}
                    errors={errors?.[type === 'location' ? 'location.coordinates.latitude' : 'zone.marker.latitude']}
                />
            </div>
            {isMapModalVisible && (
                <MapModal
                    title="Placer un repère"
                    center={marker ?? initialPosition}
                    zone={backgroundPolygon}
                    marker={marker}
                    polygon={polygon}
                    action={action}
                    onSubmit={handleSubmit}
                    onCancel={() => setMapModalVisible(false)}
                />
            )}
        </div>
    )
}

export default PositionPicker;